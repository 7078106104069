import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/base/standard-page.scss';
import '../assets/styles/pages/accident-management.scss';
import '../assets/styles/base/modern-page.scss';
import Layout from '../components/layout';
import SubNav from "../components/sub-nav";
import SEO from '../components/seo';
import Coverage from '../components/crash-management/coverage';
import HomeIcon1 from '../images/crash-management/home-icon1.png';
import HomeIcon2 from '../images/crash-management/home-icon2.png';
import HomeIcon3 from '../images/crash-management/home-icon3.png';
import HomeIcon4 from '../images/crash-management/home-icon4.png';
import Tyres4U from '../images/logos/Tyreright.png';
import SGFleetLogo from '../images/logos/sgfleet-logo.png';
import SmhLogo from '../images/logos/smh-logo.png';
import PPLogo from '../images/logos/PandPlogo.png';
import AutoNewsLogo from '../images/logos/Fleet-Auto-News-Logo.png';
import UberLogo from '../images/Uber-Carshare-Logo.inline.svg';
import AccidentSubNav from '../components/accident-sub-nav';

import CrashManagementLogo from '../images/Crash-Management-Logo.png';
import DingGoLogo from '../images/DingGo-Logo.png';
import DoubleDownIcon from '../images/Icons/links/double-down.svg';
import DoubleRightIcon from '../images/Icons/links/double-right.svg';
import FleetAnimatedSvg from '../images/animated-svg/B-FleetManagement/FleetManagement.svg';
import RepairersAnimatedSvg from '../images/animated-svg/C-Repairers/Repairers.svg';
import CommsAnimatedSvg from '../images/animated-svg/D-Comms/Comms.svg';
import AllocationAnimatedSvg from '../images/animated-svg/E-Allocation/Allocation.svg';
import FnolAnimatedSvg from '../images/animated-svg/A-FNOL/FNOL.svg';
import IconWelfare from '../images/Icons/Welfare.svg';
import IconPhone from '../images/Icons/Phone.svg';
import IconWeb from '../images/Icons/Web.svg';
import IconAutomation from '../images/Icons/Automation.svg';
import IconDashboard from '../images/Icons/Dashboard.svg';
import IconDataExport from '../images/Icons/DataExport.svg';
import IconStatusCheck from '../images/Icons/StatusCheck.svg';
import IconBilling from '../images/Icons/Billing.svg';
import IconPortal from '../images/Icons/Portal.svg';
import IconQuote from '../images/Icons/Quote.svg';
import IconCompareQuotes from '../images/Icons/Compare-Quotes.svg';
import IconPickupDropoff from '../images/Icons/Pickup-Dropoff.svg';
import IconEndToEnd from '../images/Icons/EndToEnd.svg';
import IconUpdates from '../images/Icons/Updates.svg';
import IconThumbsUp from '../images/Icons/Thumbsup.svg';
import IconNetworkGeneric from '../images/Icons/RepairerNetwork-Generic.svg';
import IconAssessmentThreshold from '../images/Icons/AssessmentThreshold.svg';
import IconNetworkSelected from '../images/Icons/RepairerNetwork-Selected.svg';
import IconAllTypes from '../images/Icons/AllTypes.svg';
import IconTick from '../images/Icons/tick.svg';
import IconQuoteLeft from '../images/Icons/quote-left.svg';
import IconQuoteRight from '../images/Icons/quote-right.svg';
import IllustrationAllAustralians from '../images/Icons/Illustrated/All-Australians.svg';
import IllustrationThumbsUp from '../images/Icons/Illustrated/Thumbsup.svg';
import IllustrationRepairers from '../images/Icons/Illustrated/Repairers.svg';
import IllustrationNZ from '../images/Icons/Illustrated/NZ.svg';
import IllustrationSME from '../images/Icons/Illustrated/SME.svg';
import IllustrationCarShare from '../images/Icons/Illustrated/Carshare.svg';
import IllustrationCompareQuotes from '../images/Icons/Illustrated/Compare-Quotes.svg';
import IllustrationGovernment from '../images/Icons/Illustrated/Government.svg';
import IllustrationPrivateVehicle from '../images/Icons/Illustrated/Private-Vehicle.svg';
import IllustrationRental from '../images/Icons/Illustrated/Rental.svg';
import IllustrationRepairComplete from '../images/Icons/Illustrated/Repair-Complete.svg';
import IllustrationSelectRepairer from '../images/Icons/Illustrated/Select-Repairer.svg';
import IllustrationSnapPhoto from '../images/Icons/Illustrated/Snap-Photo.svg';
import IllustrationUserSatisfaction from '../images/Icons/Illustrated/User-Satisfaction.svg';
import LogoAldi from '../images/Partner-Logos/ALDI.svg';
import LogoAldiMono from '../images/Partner-Logos/ALDI-Mono.svg';
import LogoAusgov from '../images/Partner-Logos/Ausgov.svg';
import LogoAusgovMono from '../images/Partner-Logos/Ausgov-Mono.svg';
import LogoLlw from '../images/Partner-Logos/LLW.svg';
import LogoLlwMono from '../images/Partner-Logos/LLW-Mono.svg';
import LogoAusgrid from '../images/Partner-Logos/AusGrid.svg';
import LogoAusgridMono from '../images/Partner-Logos/AusGrid-Mono.svg';
import LogoDulux from '../images/Partner-Logos/Dulux.svg';
import LogoDuluxMono from '../images/Partner-Logos/Dulux-Mono.svg';
import LogoHolcim from '../images/Partner-Logos/Holcim.svg';
import LogoHolcimMono from '../images/Partner-Logos/Holcim-Mono.svg';
import LogoNab from '../images/Partner-Logos/NAB.svg';
import LogoNabMono from '../images/Partner-Logos/NAB-Mono.svg';
import LogoSgfleet from '../images/Partner-Logos/sgfleet.svg';
import LogoSgfleetMono from '../images/Partner-Logos/sgfleet-Mono.svg';
import LogoUber from '../images/Partner-Logos/Uber-Carshare.svg';
import LogoUberMono from '../images/Partner-Logos/Uber-Carshare-Mono.svg';
import CrashManagementCta from "../components/crash-management/crash-management-cta";
import LogoBunningsMono from "../images/Partner-Logos/bunnings-mono.svg";
import LogoSixtMono from "../images/Partner-Logos/sixt-mono.svg";
import LogoSupagasMono from "../images/Partner-Logos/supagas-mono.svg";
import LogoTuroMono from "../images/Partner-Logos/turo-mono.svg";
import LogoVeoliaMono from "../images/Partner-Logos/veolia-mono.svg";


const showCustomerSector = (e, sectorName) => {
  const selectedCustomerSector = document.querySelectorAll(`[data-sector=${sectorName}]`)[0];
  const allCustomerSectors = document.getElementsByClassName('customer-sector-content');
  const selectedTab = e.currentTarget;
  const allTabs = document.getElementsByClassName('customer-sector-tab');

  for (const customerSector of allCustomerSectors) {
    customerSector.classList.add('hide');
  }

  selectedCustomerSector.classList.remove('hide');

  for (const tab of allTabs) {
    tab.classList.remove('customer-sector-tab-selected');
  }

  selectedTab.classList.add('customer-sector-tab-selected');
};

const CrashManagement = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  return (
    <Layout wrapperClass="modern-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO title="DingGo | Accident Management"/>
      <div className="main-wrapper modern-page">
        <CrashManagementCta countryCode={data.general.country_code} crashManagementPage />
        <section className="py-7 bg-light-orange-gradient">
          <div className="container">
            <div className="row justify-content-center m20" id="productDescription">
              <div className="col-10">
                <h2 className="modern-h2 text-orange text-center">How DingGo Crash Management Works</h2>
                <p className="text-blue text-description text-center">
                  Here’s what you can expect from DingGo Crash Management as a driver, manager or repairer.
                </p>
              </div>
            </div>
            <div className="row justify-content-center m40 product-feature-set">
              <div className="col-6 product-feature-image">
                <img src={FnolAnimatedSvg} alt="FNOL" />
              </div>
              <div className="col-6 product-feature-content">
                <h3 className="modern-h3 text-orange product-feature-heading">Accident Lodgement</h3>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconWelfare} alt="Welfare" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    24/7 phone support including make safe and welfare checks every hour.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconPhone} alt="Phone" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Phone incident lodgement with all customers recognised.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconWeb} alt="Web" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Self serve digital lodgement direct from mobile phone. No apps needed.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconAutomation} alt="Automation" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Instant receipts post lodgement and insurance claim auto lodgement.
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center m40 product-feature-set">
              <div className="col-6 product-feature-image">
                <img src={FleetAnimatedSvg} alt="Fleet Management"/>
              </div>
              <div className="col-6 product-feature-content">
                <h3 className="modern-h3 text-orange product-feature-heading">Fleet Repair Management</h3>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconDashboard} alt="Dashboard" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Fleet dashboard for live tracking all jobs including job full timeline.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconDataExport} alt="Data Export" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Real time reporting with comprehensive data and export functions.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconStatusCheck} alt="Status Check" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Separate online self serve job status portal for all drivers.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconBilling} alt="Billing" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Integrated billing so all costs come through only one supplier.
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center m40 product-feature-set">
              <div className="col-6 product-feature-image">
                <img src={RepairersAnimatedSvg} alt="Repairers"/>
              </div>
              <div className="col-6 product-feature-content">
                <h3 className="modern-h3 text-orange product-feature-heading">State of the Art Repairer Portal</h3>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconPortal} alt="Portal" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Bespoke repairer portal which all jobs are managed through.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconQuote} alt="Quote" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Smart quoting AI integrations for drag and drop autofill quoting.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconCompareQuotes} alt="Compare Quotes" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Real time repairer specific reporting.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconPickupDropoff} alt="Pickup and Dropoff" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Replacement vehicles synchronised with repair pickup and drop off.
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center m40 product-feature-set">
              <div className="col-6 product-feature-image">
                <img src={CommsAnimatedSvg} alt="Repairers"/>
              </div>
              <div className="col-6 product-feature-content">
                <h3 className="modern-h3 text-orange product-feature-heading">Automated Communications</h3>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconEndToEnd} alt="End to End Service" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    All updates, communications and reminders automatically generated.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconUpdates} alt="Updates" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Automated diary invitation and reminders for all repairs.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconStatusCheck} alt="Staus Check" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Separate online self serve job status portal for all drivers.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconThumbsUp} alt="Thumbs Up" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Every job is reviewed to ensure the best service.
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center m40 product-feature-set">
              <div className="col-6 product-feature-image">
                <img src={AllocationAnimatedSvg} alt="Repairer Allocation"/>
              </div>
              <div className="col-6 product-feature-content">
                <h3 className="modern-h3 text-orange product-feature-heading">Smart Repairer Allocation</h3>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconNetworkGeneric} alt="Repairer Network" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    One of the largest networks of independent repairers in
                    {' '}
                    {data.general.country_code === 'au' && 'Australia.'}
                    {data.general.country_code === 'nz' && 'New Zealand.'}
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconAssessmentThreshold} alt="Assessment" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Smart allocation of repairers based repair requirements and proximity.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconNetworkSelected} alt="Repairer Network" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    Ability to set micro networks of preferred repairers.
                  </div>
                </div>
                <div className="product-feature">
                  <div className="product-feature-icon-wrapper">
                    <img src={IconAllTypes} alt="All Vehicle Types" className="product-feature-icon"/>
                  </div>
                  <div className="product-feature-description">
                    All repair types and specialists including EV and heavy vehicles.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-7 bg-light-orange">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="modern-h2 text-blue text-center">DingGo Crash Management Product Overview</h2>
              </div>
            </div>
            <div className="row justify-content-center align-items-stretch">
              <div className="col-12">
                <div className="customer-sector">
                  <div className="customer-sector-tabs">
                    <div
                      className="customer-sector-tab customer-sector-tab-selected"
                      onClick={(e) => {
                        showCustomerSector(e, 'private');
                      }}
                    >
                      <img src={IllustrationPrivateVehicle} alt="Private Vechile" className="customer-sector-tab-icon"/>
                      <div className="customer-sector-tab-text">Private Vehicle</div>
                    </div>
                    <div
                      className="customer-sector-tab"
                      onClick={(e) => {
                        showCustomerSector(e, 'sme');
                      }}
                    >
                      <img src={IllustrationSME} alt="SME Fleets" className="customer-sector-tab-icon"/>
                      <div className="customer-sector-tab-text">SME Fleets</div>
                    </div>
                    <div
                      className="customer-sector-tab"
                      onClick={(e) => {
                        showCustomerSector(e, 'rental');
                      }}
                    >
                      <img src={IllustrationRental} alt="Rental Fleets" className="customer-sector-tab-icon"/>
                      <div className="customer-sector-tab-text">Rental Fleets</div>
                    </div>
                    <div
                      className="customer-sector-tab"
                      onClick={(e) => {
                        showCustomerSector(e, 'car-share');
                      }}
                    >
                      <img src={IllustrationCarShare} alt="Car Share" className="customer-sector-tab-icon"/>
                      <div className="customer-sector-tab-text">Car Share</div>
                    </div>
                    <div
                      className="customer-sector-tab"
                      onClick={(e) => {
                        showCustomerSector(e, 'government');
                      }}
                    >
                      <img src={IllustrationGovernment} alt="Government" className="customer-sector-tab-icon"/>
                      <div className="customer-sector-tab-text">Government</div>
                    </div>
                  </div>
                  <div className="customer-sector-contents">
                    <div className="customer-sector-content" data-sector="private">
                      <div className="customer-sector-content-main">
                        <img src={IllustrationPrivateVehicle} alt="Private Vechile"
                             className="customer-sector-background-image"/>
                        <div className="customer-sector-heading">Club DingGo</div>
                        <ul className="customer-sector-features">
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              24/7 accident roadside assistance ensuring your safety and support post accident
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              We manage the entire accident and repair process for you
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              All insurance claims lodgement and management handled for you
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Replacement vehicles provided for all not at fault claims or if included in your policy
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              The best crash and repair experience regardless of who you are insured with
                            </span>
                          </li>
                        </ul>
                      </div>
                      <a href="/demo" className="customer-sector-action">Request a demo</a>
                    </div>
                    <div className="customer-sector-content hide" data-sector="sme">
                      <div className="customer-sector-content-main">
                        <img src={IllustrationSME} alt="Small Medium Enterprise"
                             className="customer-sector-background-image"/>
                        <div className="customer-sector-heading">SME Fleets</div>
                        <ul className="customer-sector-features">
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              24/7 accident and phone support and online access
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Manage all your repairs on a single, customisable platform
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              One user can manage all repairs, authorisations and bookings in an easy online portal
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              All drivers can self-serve repair claims and even manage their own repair
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              One supplier for all requirements and billing
                            </span>
                          </li>
                        </ul>
                      </div>
                      <a href="/demo" className="customer-sector-action">Request a demo</a>
                    </div>
                    <div className="customer-sector-content hide" data-sector="rental">
                      <div className="customer-sector-content-main">
                        <img src={IllustrationRental} alt="Rental"
                             className="customer-sector-background-image"/>
                        <div className="customer-sector-heading">Rental Fleets</div>
                        <ul className="customer-sector-features">
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Reduce admin with by streamlining repair systems and processes into one digital platform
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Flexible configuration and platform customisation
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Comprehensive reporting and analytics to track all key business metrics
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Increase network capacity and reduce dependency
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Digital quoting for faster quote turnaround and competitiveness
                            </span>
                          </li>
                        </ul>
                      </div>
                      <a href="/demo" className="customer-sector-action">Request a demo</a>
                    </div>
                    <div className="customer-sector-content hide" data-sector="car-share">
                      <div className="customer-sector-content-main">
                        <img src={IllustrationCarShare} alt="Car Share"
                             className="customer-sector-background-image"/>
                        <div className="customer-sector-heading">Car Share</div>
                        <ul className="customer-sector-features">
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Reduce admin with by streamlining repair systems and processes into one digital platform
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Flexible configuration and platform customisation
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Easy mass claims monitoring, management and authorisation
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Comprehensive reporting and analytics to track all key business metrics
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Largest network of independent repairers in ANZ including regionally
                            </span>
                          </li>
                        </ul>
                      </div>
                      <a href="/demo" className="customer-sector-action">Request a demo</a>
                    </div>
                    <div className="customer-sector-content hide" data-sector="government">
                      <div className="customer-sector-content-main">
                        <img src={IllustrationGovernment} alt="Government"
                             className="customer-sector-background-image"/>
                        <div className="customer-sector-heading">Government</div>
                        <ul className="customer-sector-features">
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              24/7 accident and phone support and online access
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Flexible configuration and platform customisation
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Comprehensive reporting and analytics to track all key business metrics
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Online platform with controlled access levels and visibility and partner integration
                            </span>
                          </li>
                          <li className="customer-sector-feature">
                            <img src={IconTick} alt="Tick" className="customer-sector-tick"/>
                            <span className="customer-sector-content-text">
                              Strict security and compliance with all platform information hosted in
                              {' '}
                              {data.general.country_code === 'au' && 'Australia.'}
                              {data.general.country_code === 'nz' && 'New Zealand.'}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <a href="/demo" className="customer-sector-action">Request a demo</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-7 bg-light-purple">
          <div className="container">
            <div className="row justify-content-left">
              <div className="col-8">
                <h2 className="modern-h2 text-blue">
                  Why DingGo Crash Management is Trusted by Clients across Australia and New Zealand
                </h2>
              </div>
            </div>
            <div className="row justify-content-left">
              <div className="col-12">
                <div className="proof-points">
                  <div className="proof-point">
                    {data.general.country_code === 'au' &&
                      <img src={IllustrationAllAustralians} alt="Australia" className="proof-point-icon"/>
                    }
                    {data.general.country_code === 'nz' &&
                      <img src={IllustrationNZ} alt="New Zealand" className="proof-point-icon"/>
                    }
                    Trusted by 500+ fleets
                  </div>
                  <div className="proof-point">
                    <img src={IllustrationThumbsUp} alt="Australia" className="proof-point-icon"/>
                    <div className="proof-point-text">94% of Fleet Managers would recommend DingGo</div>
                  </div>
                  <div className="proof-point">
                    <img src={IllustrationUserSatisfaction} alt="User Satisfaction" className="proof-point-icon"/>
                    <div className="proof-point-text">Average driver experience rating of 4.7/5.0</div>
                  </div>
                  <div className="proof-point">
                    <img src={IllustrationRepairComplete} alt="Repair Complete" className="proof-point-icon"/>
                    <div className="proof-point-text">80,000+ vehicles under management</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="divider-gradient"/>
              </div>
            </div>
            <div className="row justify-content-left">
              <div className="col-6">
                <h3 className="modern-h3 text-blue">Hear From Our Trusted Client Partners</h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="quote-modern">
                  <img src={IconQuoteLeft} alt="Quote Mark" className="quote-modern-left-mark"/>
                  <div className="quote-modern-text">
                    DingGo provides a great customer experience and is truly unique in the market.
                    It’s an innovative approach that can add a lot of value to our fleet customers
                    and save them lots of time and stress.
                  </div>
                  <img src={IconQuoteRight} alt="Quote Mark" className="quote-modern-right-mark"/>
                  <div className="quote-modern-author">
                    — Robbie Blau
                    <br/>
                    CEO
                  </div>
                  <img src={LogoSgfleet} alt="SG Fleet" className="quote-modern-logo"/>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="quote-modern">
                  <img src={IconQuoteLeft} alt="Quote Mark" className="quote-modern-left-mark"/>
                  <div className="quote-modern-text">
                    Since partnering with DingGo as our accident management provider we now have the visibility and ability to monitor and manage accidents, incidents and repairs for our varied fleet of assets. DingGo staff have proved to be knowledgeable and supportive through all aspects of managing the accident, incident and repair process for our fleet. 
                    <br/>
                    I would recommend DingGo as an accident manager partner to any company with a fleet of vehicles looking for accident management tailored to their needs.
                  </div>
                  <img src={IconQuoteRight} alt="Quote Mark" className="quote-modern-right-mark"/>
                  <div className="quote-modern-author">
                    — Lee Asplin
                    <br/>
                    Fleet Engineering & Strategy
                  </div>
                  <img src={LogoAusgrid} alt="Ausgrid" className="quote-modern-logo"/>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="divider-gradient"/>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <h3 className="modern-h3 text-blue text-center">More People We Work With</h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="customer-logos">
                  <img src={LogoAusgovMono} alt="Australian Government" className="customer-logo"/>
                  <img src={LogoHolcimMono} alt="HOLCIM" className="customer-logo"/>
                  <img src={LogoNabMono} alt="NAB" className="customer-logo"/>
                  <img src={LogoDuluxMono} alt="Dulux" className="customer-logo"/>
                  <img src={LogoAldiMono} alt="Aldi" className="customer-logo"/>
                  <img src={LogoAusgridMono} alt="Ausgrid" className="customer-logo"/>
                  <img src={LogoSgfleetMono} alt="SG Fleet" className="customer-logo"/>
                  <img src={LogoLlwMono} alt="Lives Lived Well" className="customer-logo"/>
                  <img src={LogoBunningsMono} alt="Bunnings Warehouse" className="customer-logo"/>
                  <img src={LogoSixtMono} alt="Sixt" className="customer-logo"/>
                  <img src={LogoSupagasMono} alt="Supagas" className="customer-logo"/>
                  <img src={LogoTuroMono} alt="Turo" className="customer-logo"/>
                  <img src={LogoVeoliaMono} alt="Veolia" className="customer-logo"/> 
                </div>
              </div>
            </div>
          </div>
        </section>

        {data.general.country_code === 'au' && <Coverage />}

        <section className="py-7 bg-light-blue">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-8">
                <h2 className="modern-h2 text-blue">Our Values</h2>
                <p className="text-blue text-description">A little bit about how we look at the world.</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-8">
                <div className="dinggo-value">
                  <img src={IllustrationAllAustralians} alt="Australia" className="dinggo-value-image" />
                  <div className="dinggo-value-text text-blue">
                    Our mission at DingGo is to ensure everyone can afford the time and money
                    to have their car repaired back to the condition they bought it in.
                  </div>
                </div>
                <div className="dinggo-value dinggo-value-flipped">
                  <img src={IllustrationRepairComplete} alt="Repair Complete" className="dinggo-value-image" />
                  <div className="dinggo-value-text text-blue">
                    Our promise to every customer is to provide the easiest way to repair your
                    car with maximum care and minimum cost.
                  </div>
                </div>
                <div className="dinggo-value">
                  <img src={IllustrationUserSatisfaction} alt="User Satisfaction" className="dinggo-value-image" />
                  <div className="dinggo-value-text text-blue">
                    At DingGo the values we live by are to provide care, value and ease to help
                    improve every human interaction we have (be it digital or physical).
                  </div>
                </div>
                <div className="dinggo-value dinggo-value-flipped">
                  <img src={IllustrationRepairers} alt="Repairers" className="dinggo-value-image" />
                  <div className="dinggo-value-text text-blue">
                    Our repairers are our favourite humans. We put a lot of time and energy into
                    helping send great customers their way. That is why we only work with the best,
                    independent repairers who are highly respected by their customers and communities.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </Layout>
  );
};

export default CrashManagement;